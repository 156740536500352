<template>
  <div class="home">
    <MDBCard>
      <MDBCardBody>
        <div class="row">

          <div class="col-md-4">
            <img src="@/assets/logo.png" class="img-fluid">
          </div>

          <div class="col-md-8 text-start">

            <p>{{ $t("home.description") }}</p>
            <div class="mb-3">
              <a v-bind:href="$t('home.more.link')" target="_blank">
                <button class="btn btn-primary btn-block">{{ $t("home.more") }}
                  <font-awesome-icon icon="external-link-square-alt" class="ms-1"/>
                </button>
              </a>
            </div>

            <!--                        <p>{{ $t("home.description2") }}</p>-->
            <!--                        <ul>-->
            <!--                            <li v-for="site in websites" v-bind:key="site.url"><a :href="site.url">{{ site.name }}</a></li>-->
            <!--                        </ul>-->

          </div>

        </div>
      </MDBCardBody>
    </MDBCard>
  </div>
</template>

<script>
import {MDBCard, MDBCardBody} from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBCard, MDBCardBody
  },
  data() {
    return {
      websites: [
        {
          url: "https://www.topkamc.pl",
          name: "www.topkamc.pl"
        },
        {
          url: "https://www.listaserwerow.pl",
          name: "www.listaserwerow.pl"
        }
      ]
    }
  }
}
</script>
