import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkSquareAlt, faExclamationTriangle, faCheckCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(faExternalLinkSquareAlt);
library.add(faExclamationTriangle);
library.add(faCheckCircle);
library.add(faCircleNotch);

export {
    FontAwesomeIcon,
    FontAwesomeLayers
}
