<template>
  <div class="vote text-center">
    <MDBCard>

      <MDBCardBody v-if="voteScene === undefined">
        <br><br><br><br><br><br><br>
      </MDBCardBody>

      <MDBCardBody v-if="voteScene === 'START'" style="z-index: 0">
        <h1 class="my-2">{{ $t(`vote.title`) }}</h1>
        <MDBRow class="px-md-5 mt-3">
          <MDBCol :col="bridgeName ? '4' : '6'">
            <img v-bind:src="listIconUrl" class="img-fluid img-thumbnail" style="width: 50%; border-radius: 100%" height="128" width="128">
            <p class="mb-0 mt-2"><strong>{{ $t('vote.server-list') }}</strong></p>
            <p class="text-black-50">{{ listName }}</p>
          </MDBCol>
          <MDBCol v-if="bridgeName" col="4" class="d-flex flex-column justify-content-center">
            <span class="alert alert-primary"><strong>{{ $t('vote.bridge') }}</strong>:<br>{{ bridgeName }}</span>
          </MDBCol>
          <MDBCol :col="bridgeName ? '4' : '6'">
            <img v-bind:src="serverIconUrl" class="img-fluid img-thumbnail" style="width: 50%; border-radius: 100%" height="128" width="128">
            <p class="mb-0 mt-2"><strong>{{ $t('vote.server-name') }}</strong></p>
            <p class="text-black-50">{{ serverName }}</p>
          </MDBCol>
        </MDBRow>
        <div class="my-3">
          <a v-bind:href="votingUrl">
            <button class="btn btn-primary pulse">{{ $t('vote.continue-voting') }}
              <font-awesome-icon icon="external-link-square-alt" class="ms-1"/>
            </button>
          </a>
        </div>
      </MDBCardBody>

      <MDBCardBody v-if="voteScene === 'ERROR'">
        <MDBRow>

          <MDBCol xl="4" class="p-2">
            <font-awesome-icon icon="exclamation-triangle" style="font-size: 10rem" class="text-danger"/>
          </MDBCol>

          <MDBCol xl="8">
            <h1>{{ $t(`vote.error.title`) }}</h1>
            <p>{{ $t("vote.error.description") }}</p>
            <p class="alert alert-danger mb-0">
              <span v-if="votingErrorDescription"><strong>{{ $t("vote.error.failure") }}:</strong> {{ $t(`vote.error.failure.${votingErrorCode}`) }} {{
                  $t('vote.error.message')
                }} (<em>{{ votingErrorDescription }}</em>)</span>
              <span v-else><strong>{{ $t("vote.error.failure") }}:</strong> {{ $t(`vote.error.failure.${votingErrorCode}`) }}</span>
            </p>
          </MDBCol>

        </MDBRow>
      </MDBCardBody>

      <MDBCardBody v-if="voteScene === 'RETURN'">
        <MDBRow>

          <MDBCol xl="2" class="p-2">
            <font-awesome-layers style="font-size: 10rem" class="text-info">
              <font-awesome-icon icon="circle-notch" :spin="true"/>
              <strong class="fa-stack-1x inside-text">{{ counterValue }}</strong>
            </font-awesome-layers>
          </MDBCol>

          <MDBCol xl="8">
            <h1>{{ $t(`vote.return.title`) }}</h1>
            <p>{{ $t(`vote.return.description`) }}</p>
            <p class="alert alert-info mb-0">{{ $t(`vote.return.alert`) }}</p>
          </MDBCol>

        </MDBRow>
      </MDBCardBody>

      <MDBCardBody v-if="voteScene === 'SUCCESS'">
        <MDBRow>

          <MDBCol xl="4" class="p-2">
            <font-awesome-icon icon="check-circle" style="font-size: 10rem" class="text-success"/>
          </MDBCol>

          <MDBCol xl="8">
            <h1>{{ $t(`vote.success.title`) }}</h1>
            <p>{{ $t(`vote.success.description`) }}</p>
            <p class="alert alert-success mb-0">{{ $t(`vote.success.alert`) }}</p>
          </MDBCol>

        </MDBRow>
      </MDBCardBody>

    </MDBCard>
  </div>
</template>

<script>
import {MDBCard, MDBCardBody, MDBCol, MDBRow} from 'mdb-vue-ui-kit';
import * as placeholder from '@/util/placeholder';

export default {
  components: {
    MDBRow, MDBCol, MDBCard, MDBCardBody
  },
  data() {
    return {
      voteScene: undefined,
      listName: "...",
      bridgeName: undefined,
      listIconUrl: placeholder.pixel,
      votingUrl: "",
      votingBackground: "",
      serverName: "...",
      serverIconUrl: placeholder.pixel,
      counterValue: 31,
      votingErrorCode: undefined,
      votingErrorDescription: undefined,
    }
  },
  methods: {
    returnCountdown() {
      const decreaseCounter = () => {
        this.counterValue--;
        if (this.counterValue > 0) {
          setTimeout(decreaseCounter, 1000);
          return;
        }
        this.counterValue = "?";
      };
      decreaseCounter();
    },
    updateVoteInfo(callback) {
      const publicVoteId = this.$route.params.id;
      const voteRequest = this.axios.get(`/v1/voter/${publicVoteId}`, {validateStatus: false});
      const onSuccess = (response) => {
        if (response.status === 404) {
          this.voteScene = "ERROR";
          this.votingErrorCode = "NOT_FOUND";
          return;
        }
        const data = response.data;
        this.votingBackground = data["votingBackground"];
        if (this.votingBackground) {
          document.body.style.background = this.votingBackground.charAt(0) === '#'
              ? this.votingBackground
              : `#1c2a48 url(${this.votingBackground}) no-repeat center center fixed`;
          document.body.style.backgroundSize = "cover";
        }
        this.listName = data["listName"];
        if (this.listName.includes("+")) {
          this.bridgeName = this.listName.split("+", 2)[1];
          this.listName = this.listName.split("+", 2)[0];
          if (this.bridgeName.endsWith(".okaeri.cloud") || this.bridgeName.endsWith(".okaeri.eu")) {
            this.bridgeName = "Okaeri ✅";
          }
        }
        this.listIconUrl = data["listIconUrl"] ?? require("@/assets/placeholder/question_mark.png");
        this.votingUrl = data["votingUrl"];
        this.serverName = data["serverName"];
        this.serverIconUrl = data["serverIconUrl"] ?? require("@/assets/placeholder/question_mark.png");
        this.voteScene = data["votingState"];
        this.votingErrorCode = data["votingErrorCode"];
        this.votingErrorDescription = data["votingErrorDescription"];
        callback();
      };
      voteRequest.then(onSuccess).catch((error) => {
        console.log(error);
      });
    }
  },
  created() {
    this.updateVoteInfo(() => {
      if (this.voteScene === 'RETURN') {
        this.returnCountdown();
      }
    });
  }
}
</script>

<style scoped>
.pulse {
  overflow: visible;
  position: relative;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity .5s, transform .5s;
  animation: pulse-animation 1s cubic-bezier(0.3, 0, 0.3, 0.5) infinite;
  z-index: -1;
}

@keyframes pulse-animation {
  0% {
    opacity: 0.4;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.3);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

.inside-text {
  font-size: 40%;
  top: 0.455em;
}
</style>