<template>
  <MDBContainer>
    <MDBRow>
      <MDBCol md="6" offsetMd="3" class="mt-3">
        <router-view/>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6" offsetMd="3">
        <LocaleSwitcher class="text-end mt-2"/>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher"
import {MDBCol, MDBContainer, MDBRow} from 'mdb-vue-ui-kit';

export default {
  components: {
    LocaleSwitcher,
    MDBRow, MDBCol, MDBContainer
  }
}
</script>

<style>
body {
  background-color: #1c2a48;
  box-shadow: 0 0 120px rgba(0, 0, 0, .5) inset;
  min-height: 100vh
}

#app {
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
}

@media (min-width: 768px) {
  #app {
    padding-top: 10vh;
  }
}
</style>
