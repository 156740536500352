<template>
  <div class="locale-switcher">
    <select v-model="$i18n.locale" @change="onChange($event)">
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">{{ locale.name }}</option>
    </select>
  </div>
</template>

<script>
import {getSupportedLocales} from "@/util/i18n/supported-locales"

export default {
  data() {
    return {
      locales: getSupportedLocales()
    };
  },
  methods: {
    onChange(event) {
      this.$cookie.set("ov_locale", event.target.value, "1y");
    }
  },
  created() {
    const localeCookie = this.$cookie.get("ov_locale");
    if (localeCookie !== null) {
      this.$i18n.locale = localeCookie;
    }
  }
}
</script>
