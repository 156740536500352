import 'mdb-vue-ui-kit/css/mdb.min.css'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import cookie from './plugins/cookie'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import {FontAwesomeIcon, FontAwesomeLayers} from '@/plugins/font-awesome'

const app = createApp(App)
    .use(cookie)
    .use(router)
    .use(VueAxios, axios)
    .use(i18n)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('font-awesome-layers', FontAwesomeLayers)
    .mount('#app');

app.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
