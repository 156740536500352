export default {
    pl: "🇵🇱 Polski",
    en: "🇬🇧 English",
    ru: "🇷🇺 Русский",
    es: "🇪🇸 Español",
    pt: "🇵🇹 Português",
    fr: "🇫🇷 Français",
    de: "🇩🇪 Deutsch",
    eo: "🟩 Esperanto"
}
