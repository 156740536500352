import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home'
import Vote from '@/views/Vote'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/:id',
        name: 'vote',
        component: Vote
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
